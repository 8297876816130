import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { SEO, Layout, PageHeader, MainWrapper, SimpleButton } from "components"

const MainContent = styled.div`
  padding: 60px 0;

  h1 {
    margin: 40px 0 40px 0;
    font-size: 25px;
    font-family: var(--fontSecondary);
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;

  @media (max-width: 768px) {
    display: block;
  }

  .m-btn {
    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      padding: 30px 0;
    }
  }

  .details {
    display: flex;

    .content {
      padding: 0 0 0 30px;

      @media (max-width: 768px) {
        display: block;
      }

      h2 {
        font-size: 20px;
        line-height: 30px;
        font-family: var(--fontSecondary);
        padding-top: 25px;
      }

      p {
        font-size: 14px;
        padding-bottom: 10px;
      }

      a {
        color: var(--black);
        font-size: 13px;
      }
    }
  }
`

const OnlineOrderPage = () => (
  <Layout>
    <SEO title="Online Order" />
    <PageHeader title="Online Order">
      <StaticImage
        src="../images/order-online-header.png"
        placeholder="blurred"
        alt="Menu"
        quality="100"
      />
    </PageHeader>
    <MainContent>
      <MainWrapper>
        <h1>All locations</h1>
        <Content>
          <div className="details">
            <StaticImage
              src="../images/map-2.png"
              alt="Spruzzo Restaurant & Bar - Palisades"
              placeholder="blurred"
              height={150}
              width={150}
            />
            <div className="content">
              <h2>Spruzzo Restaurant & Bar - Palisades</h2>
              <p>538 Palisades Dr, Los Angeles, CA 90272</p>
              <a href="tel:(424) 426-5900">(424) 426-5900</a>
            </div>
          </div>
          <div className="m-btn">
            <SimpleButton
              background="var(--black)"
              color="var(--white)"
              margin="40px 0 0 0"
              href="https://www.toasttab.com/spruzzopalisades/v3"
              rel="noopener noreferrer"
              target="_blank"
            >
              Order Online
            </SimpleButton>
          </div>
        </Content>
        <Content>
          <div className="details">
            <StaticImage
              src="../images/map.png"
              alt="Spruzzo Restaurant & Bar - Malibu"
              placeholder="blurred"
              height={150}
              width={150}
            />
            <div className="content">
              <h2>Spruzzo Restaurant & Bar - Malibu</h2>
              <p>29575 CA-1, Malibu, CA 90265</p>
              <a href="tel:(310) 457-8282">(310) 457-8282</a>
            </div>
          </div>
          <div className="m-btn">
            <SimpleButton
              background="var(--black)"
              color="var(--white)"
              margin="40px 0 0 0"
              href="https://www.toasttab.com/spruzzo/v3"
              rel="noopener noreferrer"
              target="_blank"
            >
              Order Online
            </SimpleButton>
          </div>
        </Content>
      </MainWrapper>
    </MainContent>
  </Layout>
)

export default OnlineOrderPage
